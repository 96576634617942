import React from 'react';
// import Tippy from '@tippyjs/react';
// import 'tippy.js/dist/tippy.css'; // optional
// import { useState } from 'react';

//! tippy has errors, will have to change ti.
function Tip({content, rest, children}){
  return (
    // <Tippy
    //   placement="top"
    //   touch={false}
    //   arrow={true}
    //   arrowType={'round'}
    //   content={content}
    //   // https://github.com/FezVrasta/popper.js/issues/535
    //   popperOptions={{
    //   modifiers: {
    //      preventOverflow: {
    //         boundariesElement: 'window',
    //      },
    //   },
    //   }}
    //   {...rest}
    // >
    <>
      {children}
    </>
  //  {/* </Tippy> */}
  );
};

export default Tip;
