import React, { useEffect } from "react";
import Icon from "spectrum-icons";
import Tip from "./Tip";
import config from "../../config/config";

export default function BottomNavBar({ newNotif, is_logged_in, loading_user }) {
  useEffect(() => {
    // add a margin bottom to the body
    document.body.style.marginBottom = "5rem";
  }, []);


  return (
    <div
      className="bg-white fixed w-full py-2 bottom-0 left-0 border-t-2 border-gray-200"
      style={{ zIndex: "9790", boxShadow: "-1px -6px 9px rgb(0,0,0,0.085)" }}
    >
      <ul className="flex items-center justify-around">
        {!loading_user ? (
          <React.Fragment>
            {/* // Go Home */}
            <Tip content="الصفحة الرئيسية">
              <li className="ml-4 bg-gray-200 relative text-gray-700 p-1 rounded-full hover:text-gray-900">
                <a href="/" className="flex">
                  {/* <Bell size={22} /> */}
                  <Icon glyph="home" size={30} />
                </a>
              </li>
            </Tip>

            {/* // add question icon */}
            <Tip content="أضف سؤال">
              <li className="ml-4 bg-gray-200 relative text-gray-700 p-1 rounded-full hover:text-gray-900">
                <a href="/question/create" className="flex">
                  <Icon glyph="plus" size={30} />
                </a>
              </li>
            </Tip>

            {/* // explore the consultors */}
            {
              config.is_advising_active ? (
                <Tip content="استكشف المستشارين">
                  <li className="ml-4 bg-gray-200 relative text-gray-700 p-1 rounded-full hover:text-gray-900">
                    <a href="/explore/advisors" className="flex">
                      <Icon glyph="explore" size={30} />
                    </a>
                  </li>
                </Tip>
              ) : null
            }

            {/* // notification icon */}
            {is_logged_in ? (
              <Tip content="إشعارات">
                <li className="ml-4 bg-gray-200 relative text-gray-700 p-1 rounded-full hover:text-gray-900">
                  <a href="/notifications" className="flex">
                    <Icon glyph="notification" size={30} />
                  </a>
                  {newNotif ? (
                    <div id="new_notif">
                      <span
                        className="absolute border-1 border-white flex items-center justify-center top-0 right-0 rounded-full bg-red-500 text-gray-100 font-bold"
                        style={{
                          width: "10px",
                          height: "10px",
                          fontSize: "10px",
                        }}
                      ></span>
                    </div>
                  ) : null}
                </li>
              </Tip>
            ) : null}
          </React.Fragment>
        ) : (
            [...Array(3)].map(() => (
              <li className="ml-4 w-8 h-8 rounded-full skeleton-shimmer"></li>
            ))
        )}
      </ul>
    </div>
  );
}
