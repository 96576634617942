import React, { useContext, useState, useEffect } from "react";
// import { LogIn } from "react-feather";
import UserContext from "../../config/UserContext";
// import Dropdown from "./Dropdown";
import axios from "../../config/axios";
import { toast } from "react-toastify";
import BottomNavBar from "./BottomNavBar";
import NavBarLinksList from "./navbar/NavBarLinksList";
// import {Redirect} from 'react-router-dom'
import config from "../../config/config";
// import resolveUserImg from '../../helpers/resolveUserImg';

export default function Navbar() {
  let { loadingUser, loggedInUser, socketIo } = useContext(UserContext);
  let [userDropdownIsOpen, setUserDropdownIsOpen] = useState(false);
  let [newNotif, setNewNotif] = useState(false);

  // Get new notifications when user refresh or something
  useEffect(() => {
    (async function () {
      try {
        let res = await axios.get("/notifications/news");
        if (res.status === 200) {
          if (res.data.newNotifs) {
            setNewNotif(true);
          }
        }
      } catch (err) {
        setNewNotif(false);
      }
    })();
  }, []);

  // Watch for new notifications, with socket
  useEffect(() => {
    let origin_page_title = document.getElementsByTagName("title")[0].text;
    if (socketIo) {
      socketIo.on("new_notification", (data) => {
        // update the tab title
        document.getElementsByTagName(
          "title"
        )[0].text = `(1) ${origin_page_title}`;
        // set new notifs
        setNewNotif(true);
      });
    }
  }, [socketIo]);

  async function handleLogout() {
    try {
      let logoutRes = await axios.get("/logout");
      if (logoutRes.status === 200) {
        window.location.href = config.client_url;
      }
    } catch (err) {
      toast("حدث خطأ ما ، يرجى إعادة المحاولة لاحقًا", {
        className: "border-r-4 border-orange-400",
      });
    }
  }

  return (
    <React.Fragment>
      {
        userDropdownIsOpen ?
         <span className='fixed top-0 left-0 w-full h-full z-50 block' onClick={() => setUserDropdownIsOpen(false)}></span> 
        : null
      }
      <nav
        id="mainNav"
        className="bg-white p-2 fixed w-full top-0 z-40 border-b border-gray-200 shadow-sm"
      >
        <div className="container flex justify-between items-center">
          <div className="sm:flex sm:items-center">
            <a href="/">
              <img src="/images/logo.png" alt="LOGO" width="90px" />
            </a>
          </div>
          <div className="block">
            <div>
              <NavBarLinksList 
                userDropdownIsOpen={userDropdownIsOpen}
                loggedInUser={loggedInUser}
                loadingUser={loadingUser}
                newNotif={newNotif}
                toggleDropDown={() => setUserDropdownIsOpen(!userDropdownIsOpen)}
                onLogout={handleLogout}
              />
            </div>
          </div>
        </div>
      </nav>

      {
        window.innerWidth < 800 
        ? 
        <BottomNavBar 
          newNotif={newNotif} 
          is_logged_in={!!loggedInUser}
          loading_user={loadingUser}
        /> 
        : null
      }
    </React.Fragment>
  );
}
