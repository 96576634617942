import React from "react";
import Dropdown from "../Dropdown";
import Icon from "spectrum-icons";
import config from "../../../config/config";
import Tip from "../Tip";
import { User, Settings, Sidebar, LogOut, Calendar } from "react-feather";

export default function NavBarLinksList({
  userDropdownIsOpen,
  loggedInUser,
  loadingUser,
  onLogout,
  toggleDropDown,
  newNotif,
}) {
  return (
    <React.Fragment>
      <ul className="flex items-center">
        {loadingUser ? (
          <React.Fragment>
            <li className="skeleton-shimmer w-8 h-8 flex rounded-full mx-2"></li>
            <li className="skeleton-shimmer w-8 h-8 flex rounded-full mx-2"></li>
          </React.Fragment>
        ) : !loggedInUser.me ? (
          <React.Fragment>
            {/* explore consultors */}
            {window.innerWidth >= 800 ? (
              config.is_advising_active ? (
                <Tip content="استكشف المستشارين">
                  <li className="ml-4 bg-gray-200 relative text-gray-700 p-1 rounded-full hover:text-gray-900">
                    <a href="/explore/advisors" className="flex">
                      <Icon glyph="explore" size={30} />
                    </a>
                  </li>
                </Tip>
              ) : null
            ) : null}
            {/* Login */}
            <Tip content="تسجيل الدخول">
              <li className="ml-4 bg-gray-200 relative text-gray-700 p-1 rounded-full hover:text-gray-900">
                <a href="/login" className="flex">
                  <Icon glyph="door-enter" size={30} />
                </a>
              </li>
            </Tip>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {window.innerWidth >= 800 ? (
              <React.Fragment>
                {/* explore consultors */}
                {config.is_advising_active ? (
                  <Tip content="استكشف المستشارين">
                    <li className="ml-4 bg-gray-200 relative text-gray-700 p-1 rounded-full hover:text-gray-900">
                      <a href="/explore/advisors" className="flex">
                        {/* <Bell size={22} /> */}
                        <Icon glyph="explore" size={30} />
                      </a>
                    </li>
                  </Tip>
                ) : null}

                {/* add question icon */}
                <Tip content="أضف سؤال">
                  <li className="ml-4 bg-gray-200 relative text-gray-700 p-1 rounded-full hover:text-gray-900">
                    <a href="/question/create" className="flex">
                      <Icon glyph="plus" size={30} />
                    </a>
                  </li>
                </Tip>

                {/* notification icon */}
                <Tip content="إشعارات">
                  <li className="ml-4 bg-gray-200 relative text-gray-700 p-1 rounded-full hover:text-gray-900">
                    <a href="/notifications" className="flex">
                      {/* <Bell size={22} /> */}
                      <Icon glyph="notification" size={30} />
                    </a>
                    {newNotif ? (
                      <div id="new_notif">
                        <span
                          className="absolute border-1 border-white flex items-center justify-center top-0 right-0 rounded-full bg-red-500 text-gray-100 font-bold"
                          style={{
                            width: "10px",
                            height: "10px",
                            fontSize: "10px",
                          }}
                        ></span>
                      </div>
                    ) : null}
                  </li>
                </Tip>
              </React.Fragment>
            ) : null}

            {/* Profile icon */}
            <Tip content="ملفك الشخصي">
              <li className="relative" id="mainNavProfileIcon">
                <div
                  onClick={toggleDropDown}
                  className="text-sm font-bold leading-none cursor-pointer bg-gray-200 relative text-gray-700 p-1 rounded-full hover:text-gray-900"
                >
                  <Icon glyph="profile" size={30} />
                </div>
                {/* {{--  User dropdown menu  --}} */}
                <Dropdown
                  isOpen={userDropdownIsOpen}
                  style={{ top: "100%" }}
                  className="border-t-4 border-blue-400 w-48"
                >
                  <div className="hover:bg-gray-200 text-gray-800">
                    <a
                      href={`/profile/${loggedInUser.me.id}`}
                      className="block py-2 px-5"
                    >
                      <User className="inline ml-2 text-gray-600" size={20} />
                      الملف
                    </a>
                  </div>
                  <div className="hover:bg-gray-200 text-gray-800">
                    <a href="/u/settings" className="block py-2 px-5">
                      <Settings
                        className="inline ml-2 text-gray-600"
                        size={20}
                      />
                      الإعدادات
                    </a>
                  </div>
                  <hr />
                  {loggedInUser.me.is_consulter === 1 ? (
                    <div className="hover:bg-gray-200 text-gray-800">
                      <a
                        href={config.consulter_url}
                        className="block py-2 px-5"
                        target="_blank"
                      >
                        <Sidebar
                          className="inline ml-2 text-gray-600"
                          size={20}
                        />
                        منطقة المستشار
                      </a>
                    </div>
                  ) : null}
                  {config.is_advising_active && (
                    <div className="hover:bg-gray-200 text-gray-800">
                      <a href="/u/consultings" className="block py-2 px-5">
                        <Calendar
                          className="inline ml-2 text-gray-600"
                          size={20}
                        />
                        استشاراتي
                      </a>
                    </div>
                  )}
                  <hr />
                  <div className="hover:bg-gray-200 text-gray-600 hover:text-red-500">
                    <button
                      onClick={onLogout}
                      className="block py-2 px-5 w-full text-right"
                    >
                      <LogOut className="inline ml-2" size={20} />
                      تسجيل خروج
                    </button>
                  </div>
                </Dropdown>
              </li>
            </Tip>
          </React.Fragment>
        )}
      </ul>
    </React.Fragment>
  );
}
