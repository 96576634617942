/**
 * Main App
 * All the routes are here
 */
import React, { useState, useEffect, lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import io from "socket.io-client";
import datetimeToDate from "./helpers/datetimeToDate";

// Get logged in user provider
import { UserProvider } from "./config/UserContext";
import config from "./config/config";
import axios from "./config/axios";

// Importing the navbar as its a common component
import Navbar from "./components/common/Navbar";
import Loader from "./components/common/Loader";
import { toast } from "react-toastify";

// Importing pages
// import Home from "./pages/Home";
const Home = lazy(() => import("./pages/Home"));

// import QuestionsByTopic from './pages/QuestionsByTopic';
const QuestionsByTopic = lazy(() => import("./pages/QuestionsByTopic"));

// import CreateQuestion from './pages/CreateQuestion';
const CreateQuestion = lazy(() => import("./pages/CreateQuestion"));

// import Search from "./pages/Search";

// import Notifications from "./pages/Notifications";
const Notifications = lazy(() => import("./pages/Notifications"));

// import SingleQuestion from "./pages/SingleQuestion";
const SingleQuestion = lazy(() => import("./pages/SingleQuestion"));

// import Login from "./pages/auth/Login";
const Login = lazy(() => import("./pages/auth/Login"));

// import Join from "./pages/auth/Join";
const Join = lazy(() => import("./pages/auth/Join"));

// import NotFound from "./pages/error/NotFound";
const NotFound = lazy(() => import("./pages/error/NotFound"));

// import UserSettings from "./pages/UserSettings";
const UserSettings = lazy(() => import("./pages/UserSettings"));

// import VerifyAccount from "./pages/VerifyAccount";
const VerifyAccount = lazy(() => import("./pages/VerifyAccount"));

// import About from "./pages/static/About";
const About = lazy(() => import("./pages/static/About"));

// import Rules from "./pages/static/Rules";
const Rules = lazy(() => import("./pages/static/Rules"));

// import PrivacyPolicy from "./pages/static/PrivacyPolicy";
const PrivacyPolicy = lazy(() => import("./pages/static/PrivacyPolicy"));

// import TermsConditions from "./pages/static/TermsConditions";
const TermsConditions = lazy(() => import("./pages/static/TermsConditions"));

// import Contact from "./pages/static/Contact";
const Contact = lazy(() => import("./pages/static/Contact"));

// import Badges from "./pages/static/Badges";
const Badges = lazy(() => import("./pages/static/Badges"));

// import HowItWorks from "./pages/static/HowItWorks";
const HowItWorks = lazy(() => import("./pages/static/HowItWorks"));

// import UserConsultings from "./pages/UserConsultings";
const UserConsultings = lazy(() => import("./pages/UserConsultings"));

// import ConsultingRoom from "./pages/ConsultingRoom";
const ConsultingRoom = lazy(() => import("./pages/ConsultingRoom"));

// import JoinLandingPage from "./pages/JoinLandingPage";
const JoinLandingPage = lazy(() => import("./pages/JoinLandingPage"));

// import ProfileDisplayer from "./pages/ProfileDisplayer";
const ProfileDisplayer = lazy(() => import("./pages/ProfileDisplayer"));

// import ExploreAdvisors from "./pages/ExploreAdvisors";
const ExploreAdvisors = lazy(() => import("./pages/ExploreAdvisors"));

// import OrderPaymentResult from "./pages/OrderPaymentResult";
const OrderPaymentResult = lazy(() => import("./pages/OrderPaymentResult"));

// import hasDailyPoints from "./api/daily-points/hasDailyPoints";
// import { toast } from "react-toastify";

function App() {
  let [loggedInUser, setloggedInUser] = useState({});
  let [loadingUser, setLoadingUser] = useState(true);
  // let [isNavbarHidden, setIsNavbarHidden] = useState(false);
  let [socketIo, setSocketIo] = useState(null);
  // let socket = io.connect(`${config.server_url}`, { transports: ['websocket'] });

  // socket.on('connect', () => {
  //   console.log('Welcome to Tsaol!');
  // });

  // remove the saved order
  // useEffect(() => {
  //   if(window.localStorage.getItem('my_order') && window.localStorage.getItem('order_to_show')){
  //     window.localStorage.removeItem('my_order')
  //     window.localStorage.removeItem('order_to_show')
  //   }
  // }, [])

  // Get the logged in user
  useEffect(() => {
    (async function () {
      try {
        let loggedInUser = await axios.get("/me");
        setloggedInUser(loggedInUser.data);
      } catch (e) {
        setloggedInUser(false);
      }
      setLoadingUser(false)
    })();
  }, []);

  // daily points
  useEffect(() => {
    (async function () {
      try {
        if (Object.keys(loggedInUser).length > 0) {
          const claimed_daily_points = localStorage.getItem(
            "claimed_daily_points"
          );
          // so we dont have to fetch everytime
          const right_to_claim = !claimed_daily_points
          ? true
          : datetimeToDate(claimed_daily_points) < datetimeToDate(new Date());
          
          // console.log('right_to_claim: ', right_to_claim, claimed_daily_points, !claimed_daily_points)
          
          if (right_to_claim) {
            // fetch if user has the daily points rights
            const hasDailyPoints = (
              await import("./api/daily-points/hasDailyPoints")
            ).default;

            let hasEarnedPoints = await hasDailyPoints();
            if (hasEarnedPoints) {
              toast("النقاط اليومية: تم إضافة 5 نقاط إلى حسابك", {
                className: "successToast",
              });
              localStorage.setItem("claimed_daily_points", new Date());
            }
          }
        }
      } catch (err) {
        console.log("Daily points failed, sorry !");
      }
    })();
  }, [loggedInUser]);

  // connect to socket
  useEffect(() => {
    let socket = io.connect(`${config.server_url}`, {
      transports: ["websocket"],
    });

    // socket.on('connect', () => {
    //   console.log('Welcome to Tsaol!');
    // });

    // socket.on('disconnect', () => {
    //   console.log('Bye !')
    // });

    setSocketIo(socket);
  }, []);

  return (
    <React.Fragment>
      <UserProvider value={{ loadingUser, loggedInUser, socketIo }}>
        {/* main nav bar */}
        {window.location.pathname === "/get-started" ? null : <Navbar />}

      <Suspense fallback={<Loader className='mx-auto mt-32' />}>
        <Router>
          <Switch>
              <Route exact path="/" component={Home} />

              <Route
                exact
                path="/topic/:topic_name"
                component={QuestionsByTopic}
              />

            {/* for only logged in users */}
              <Route
                exact
                path="/question/create"
                component={() => {
                  if (loadingUser) {
                    return <Loader className="my-40 mx-auto" />;
                  } else {
                    if (!loadingUser && loggedInUser) {
                      return <CreateQuestion />;
                    } else if (!loadingUser && !loggedInUser) {
                      return <Redirect to="/login" />;
                    }
                  }
                }}
              />

            {/* Search route */}
            {/* <Route exact path="/search/:sort/:searchFor" component={Search} /> */}

            {/* users/consulters profile, will display the right design for the right user */}
              <Route
                exact
                path={[
                  "/profile/:user_id",
                  "/profile/:user_id/answers",
                  "/profile/:user_id/reviews",
                ]}
                component={ProfileDisplayer}
              />

              <Route
                exact
                path="/verify/:token"
                component={(props) => {
                  if (loadingUser) {
                    return <Loader className="my-40 mx-auto" />;
                  } else {
                    if (!loadingUser && loggedInUser) {
                      return <VerifyAccount props={props} />;
                    } else if (!loadingUser && !loggedInUser) {
                      return <Redirect to="/login" />;
                    }
                  }
                }}
              />

              <Route
                exact
                path="/u/settings"
                component={() => {
                  if (loadingUser) {
                    return <Loader className="my-40 mx-auto" />;
                  } else {
                    if (!loadingUser && loggedInUser) {
                      return <UserSettings />;
                    } else if (!loadingUser && !loggedInUser) {
                      return <Redirect to="/login" />;
                    }
                  }
                }}
              />

            {/* Consultering */}

              <Route
                exact
                path="/get-started"
                component={(props) => {
                  if (loadingUser) {
                    return <Loader className="my-40 mx-auto" />;
                  } else {
                    return <JoinLandingPage props={props} />;
                  }
                }}
              />

              <Route
                exact
                path="/u/consultings"
                component={() => {
                  if (loadingUser) {
                    return <Loader className="my-40 mx-auto" />;
                  } else {
                    if (!loadingUser && loggedInUser) {
                      return <UserConsultings />;
                    } else if (!loadingUser && !loggedInUser) {
                      return <Redirect to="/login" />;
                    }
                  }
                }}
              />

              <Route
                exact
                path="/consulting/room/:room_id"
                component={(props) => {
                  if (loadingUser) {
                    return <Loader className="my-40 mx-auto" />;
                  } else {
                    if (!loadingUser && loggedInUser) {
                      return <ConsultingRoom props={props} />;
                    } else if (!loadingUser && !loggedInUser) {
                      return <Redirect to="/login" />;
                    }
                  }
                }}
              />

              <Route
                exact
                path={["/explore/advisors", "/explore/advisors/:category"]}
                component={(props) => {
                  if (loadingUser) {
                    return <Loader className="my-40 mx-auto" />;
                  } else {
                    return <ExploreAdvisors props={props} />;
                  }
                }}
              />

            {/* Orders */}

              <Route
                exact
                path="/order/payment-result"
                component={(props) => {
                  if (loadingUser) {
                    return <Loader className="my-40 mx-auto" />;
                  } else {
                    return <OrderPaymentResult props={props} />;
                  }
                }}
              />

            {/* User notifications */}
              <Route
                exact
                path="/notifications"
                component={() => {
                  if (loadingUser) {
                    return <Loader className="my-40 mx-auto" />;
                  } else {
                    if (!loadingUser && loggedInUser) {
                      return <Notifications />;
                    } else if (!loadingUser && !loggedInUser) {
                      return <Redirect to="/login" />;
                    }
                  }
                }}
              />

            {/* Single question */}
              <Route
                exact
                path="/q/:question_title"
                component={SingleQuestion}
              />

            {/* Auth pages */}
              <Route
                exact
                path="/login"
                component={() => {
                  if (loadingUser) {
                    return <Loader className="my-40 mx-auto" />;
                  } else {
                    if (!loadingUser && loggedInUser) {
                      return <Redirect to="/" />;
                    } else if (!loadingUser && !loggedInUser) {
                      return <Login />;
                    }
                  }
                }}
              />

              <Route
                exact
                path="/join"
                component={() => {
                  if (loadingUser) {
                    return <Loader className="my-40 mx-auto" />;
                  } else {
                    if (!loadingUser && loggedInUser) {
                      return <Redirect to="/" />;
                    } else if (!loadingUser && !loggedInUser) {
                      return <Join />;
                    }
                  }
                }}
              />

            {/* Static pages like about us ... */}
              <Route exact path="/about-us" component={About} />
              <Route
                exact
                path="/privacy-and-policy"
                component={PrivacyPolicy}
              />
              <Route exact path="/rules" component={Rules} />
              <Route
                exact
                path="/terms-and-conditions"
                component={TermsConditions}
              />
              <Route exact path="/contact-us" component={Contact} />
              <Route exact path="/badges" component={Badges} />
              <Route exact path="/how-it-works" component={HowItWorks} />

            {/* 404 page, this route must be the last one */}
              <Route component={NotFound} />
          </Switch>
        </Router>
      </Suspense>
      </UserProvider>
    </React.Fragment>
  );
}

export default App;
